<template>
  <div>
    <ul
      v-for="global in globals"
      :key="global.interestRate"
    >
      <div class="flex flex-col md:flex-row w-full">
        <!-- slider -->
        <div class="md:order-2 w-full md:w-2/3 h-auto lg:h-screen bg-white">
          <!-- slider -->
          <div
            class="relative bg-white h-2/3 w-full order-first lg:order-last outline-none"
          >
            <div v-if="!loading">
              <VueSlickCarousel
                ref="singlecarousel"
                :dots="true"
                :arrows="true"
                :infinite="true"
                :useCSS="true"
                :useTransform="true"
              >
                <div v-if="unit.mainImageURL">
                  <img
                    :src="unit.mainImageURL"
                    alt="Unit Overview"
                  />
                </div>

                <div v-if="unit.slide2ImageURL">
                  <img
                    :src="unit.slide2ImageURL"
                    alt="Unit Second Slide"
                  />
                </div>

                <div v-if="unit.slide3ImageURL">
                  <img
                    :src="unit.slide3ImageURL"
                    alt="Unit Third Slide"
                  />
                </div>
                <div v-if="unit.slide4ImageURL">
                  <img
                    :src="unit.slide4ImageURL"
                    alt="Unit Third Slide"
                  />
                </div>
                <div v-if="unit.slide5ImageURL">
                  <img
                    :src="unit.slide5ImageURL"
                    alt="Unit Third Slide"
                  />
                </div>
              </VueSlickCarousel>
              <div class="grid grid-cols-2 mt-10">
                <div class="text-right">
                  <button
                    @click="prev"
                    class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
                  >
                    &lt; Prev
                  </button>
                </div>
                <div>
                  <button
                    @click="next"
                    class="mb-3 px-2 py-1 ml-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
                  >
                    Next &gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- sidebar -->
        <div class="w-full md:w-1/3 md:order-1 p-5 bg-gray-200">
          <!-- specs -->
          <div class="w-full mt-1">
            <span
              v-if="unit.isGuaranteedRental"
              class="has-tooltip guaranteed-rental-container"
            >
              GR
              <span class="tooltip left-10 font-normal">
                Guaranteed Rental
              </span>
            </span>
            <h2 class="text-2xl headerCss font-semibold tracking-widest mt-3">
              {{ title }}
            </h2>
            <p
              v-if="unit.name"
              class="mb-2 text-xl"
            >
              Unit {{ unit.name }}
            </p>

            <UnitPrice :bigPrice="true" :normalPrice="false" :unit="unit" :settings="settings" :show="true" :tableStyle="false" />

            <p class="mt-2 text-sm">
              <span v-if="unit.floor"> {{ unit.floor }} Floor | </span>
              <span v-if="unit.aspect" >
                {{ unit.aspect }} |
              </span>
              <span v-if="unit.aspect" >
                {{ unit.view }}
              </span>
            </p>

            <!-- pills -->
            <div class="flex justify-start flex-wrap mt-2">
              <span
                v-if="
                  unit.bedrooms &&
                  unit.bedrooms > 0
                "
                class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <BedroomIcon class="mr-1" />
                {{ unit.bedrooms }}
                <span class="tooltip -top-10 left-0">Bedrooms</span>
              </span>

              <span
                v-if="
                  unit.bathrooms &&
                  unit.bathrooms > 0
                "
                class="lb-icon-pill relative has-tooltip flex flex-row mt-3 mr-2"
              >
                <BathroomIcon class="mr-1" />
                {{ unit.bathrooms }}
                <span class="tooltip -top-10 left-0">Bathrooms</span>
              </span>

              <span
                v-if="
                    unit.parking &&
                    unit.parking > 0 &&
                    title === 'The One Stellenbosch'
                  "
                class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <CarIcon class="mr-1" />
                {{ unit.parking }}
                <span class="tooltip -top-10 left-0">Parking Spot</span>
              </span>

              <span
                v-if="
                  unit.storeroom &&
                  unit.storeroom > 0 &&
                  title === 'The Carrington'
                "
                class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <StoreIcon class="mr-1" />
                {{ unit.storeroom }}
                <span class="tooltip -top-10 left-0">Storeroom</span>
              </span>

              <span
                v-if="
                unit.internalArea &&
                unit.internalArea > 0 &&
                unit.externalArea > 0
              "
                class="lb-icon-pill relative has-tooltip flex flex-row mt-3 mr-2"
              >
                <TotalIntAreaIcon class="mr-1" />
                {{ unit.internalArea }}m&sup2;
                <span class="tooltip -top-10 left-0">Internal Area</span>
              </span>

              <span
                v-if="
                unit.externalArea &&
                unit.externalArea > 0 &&
                unit.externalArea > 0
              "
                class="lb-icon-pill relative has-tooltip flex flex-row mt-3 mr-2"
              >
                <TotalExtAreaIcon class="mr-1" />
                {{ unit.externalArea }}m&sup2;
                <span class="tooltip -top-10 left-0">External Area</span>
              </span>

              <span
                v-if="
                  unit.totalArea &&
                  unit.totalArea > 0
                "
                class="lb-icon-pill relative has-tooltip flex flex-row mt-3 mr-2"
              >
                <TotalAreaIcon class="mr-1" />
                {{ unit.totalArea }}m&sup2;
                <span class="tooltip -top-10 left-0">Total Area</span>
              </span>

              <span
                v-if="unit.isAircon"
                class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
              >
                <SnowflakeIcon class="mr-1" />
                Yes
                <span class="tooltip -top-10 left-0">Aircon</span>
              </span>
            </div>

            <div
              class="unit-spec-amounts"
            >
              <div>
                <p
                  v-if="unit.levies"
                  class="mt-2 unit-detail-text"
                >
                  Levies:
                  <span class="font-semibold text-primary">
                    {{ unit.levies | prettyCurrency }}
                    <sup>pm</sup>
                  </span>
                </p>

                <p
                  v-if="unit.rates"
                  class="mt-2 unit-detail-text"
                >
                  Rates:
                  <span class="font-semibold text-primary">
                    {{ unit.rates | prettyCurrency }}
                    <sup>pm</sup>
                  </span>
                </p>

                <div v-if="settings.allowGlobalDiscount &&
                            user.loggedIn &&
                            user.profile.answeredDiscountSurvey"
                >
                  <div v-if="settings.globalDiscountType === 'amount'">
                    <p class="mt-2 unit-detail-text">
                      Est. Bond Cost:
                      <span class="font-semibold text-primary">
                        {{
                          PMT(
                            (global.interestRate /100 ) / 12,
                            20 * 12,
                            ((unit.price - settings.globalDiscountAmount) * 0.9) * -1,
                            0,
                            0
                          ) | prettyCurrency
                        }}
                        <sup>pm</sup>
                      </span>
                    </p>
                  </div>
                  <div v-if="settings.globalDiscountType === 'percentage'">
                    <p class="mt-2 unit-detail-text">
                      Est. Bond Cost:
                      <span class="font-semibold text-primary">
                        {{
                          PMT(
                            (global.interestRate /100 ) / 12,
                            20 * 12,
                            ((unit.price * ((100 - settings.globalDiscountAmount) / 100)) * 0.9) * -1,
                            0,
                            0
                          ) | prettyCurrency
                        }}
                        <sup>pm</sup>
                      </span>
                    </p>
                  </div>
                </div>
                <div v-else>
                  <p class="mt-2 unit-detail-text">
                    Est. Bond Cost:
                    <span class="font-semibold text-primary">
                      {{
                        PMT(
                          (global.interestRate /100 ) / 12,
                          20 * 12,
                          (unit.price * 0.9) * -1,
                          0,
                          0
                        ) | prettyCurrency
                      }}
                      <sup>pm</sup>
                    </span>
                  </p>
                </div>

                <div v-if="settings.allowGlobalDiscount  &&
                            user.loggedIn &&
                            user.profile.answeredDiscountSurvey"
                >
                  <div v-if="settings.globalDiscountType === 'amount' &&
                              unit.levies &&
                              unit.rates"
                  >
                    <p class="mt-2 unit-detail-text">
                      Total Monthly Cost:
                      <span class="font-semibold text-primary">
                        {{
                          (
                            unit.levies +
                            unit.rates +
                            ((-(global.interestRate / 100) / 12) *
                              ((((unit.price - settings.globalDiscountAmount) * 0.9) * -1) *
                                Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) + 0)) /
                            (Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) - 1)
                          ).toFixed(0)

                            | prettyCurrency
                        }}
                        <sup>pm</sup>
                      </span>
                    </p>
                  </div>
                  <div v-if="settings.globalDiscountType === 'percentage' &&
                              unit.levies &&
                              unit.rates"
                  >
                    <p class="mt-2 unit-detail-text">
                      Total Monthly Cost:
                      <span class="font-semibold text-primary">
                        {{
                          (
                            unit.levies +
                            unit.rates +
                            ((-(global.interestRate / 100) / 12) *
                              ((((unit.price * ((100 - settings.globalDiscountAmount) / 100)) * 0.9) * -1) *
                                Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) + 0)) /
                            (Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) - 1)
                          ).toFixed(0)

                            | prettyCurrency
                        }}
                        <sup>pm</sup>
                      </span>
                    </p>
                  </div>
                </div>
                <div v-else>
                  <p class="mt-2 unit-detail-text">
                    Total Monthly Cost:
                    <span class="font-semibold text-primary">
                      {{
                        (
                          unit.levies +
                          unit.rates +
                          ((-(global.interestRate / 100) / 12) *
                            (((unit.price * 0.9) * -1) *
                              Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) + 0)) /
                          (Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) - 1)
                        ).toFixed(0)

                          | prettyCurrency
                      }}
                      <sup>pm</sup>
                    </span>
                  </p>
                </div>

              </div>

              <div>
                <p
                  v-if="unit.unitRentalGuaranteed &&
                        unit.isGuaranteedRental"
                  class="mt-2 unit-detail-text"
                >
                  <span class="font-semibold rental-guaranteed">
                    {{ unit.unitRentalGuaranteed }} Rental Guaranteed
                  </span>
                </p>

                <div>
                  <p
                    v-if="unit.guaranteedRental &&
                        parseInt(unit.guaranteedRental, 10) > 0"
                    class="mt-2 unit-detail-text"
                  >
                    Est. Rental:
                    <span class="font-semibold text-primary">
                      {{ unit.guaranteedRental | prettyCurrency }}
                      <sup>pm</sup>
                  </span>
                  </p>
                  <p
                    v-else
                    class="mt-2 unit-detail-text"
                  >
                    Est. Rental:
                    <span class="font-semibold text-primary">
                      {{ unit.guaranteedRental | prettyCurrency }}
                      <sup>pm</sup>
                      </span>
                  </p>
                </div>

                <div v-if="settings.allowGlobalDiscount &&
                            user.loggedIn &&
                            user.profile.answeredDiscountSurvey"
                >
                  <div v-if="settings.globalDiscountType === 'amount'">
                    <p
                      v-if="unit.guaranteedRental &&
                            unit.levies &&
                            unit.rates"
                      class="mt-2 unit-detail-text"
                    >
                      Net Return:
                      <span class="font-semibold text-primary">
                        {{
                          (
                            ((unit.guaranteedRental - unit.levies - unit.rates) * 12) /
                            (unit.price - settings.globalDiscountAmount) * 100
                          ).toFixed(2)
                        }}
                        <sup>%</sup>
                      </span>
                    </p>
                  </div>
                  <div v-if="settings.globalDiscountType === 'percentage'">
                    <p
                      v-if="unit.guaranteedRental &&
                            unit.levies &&
                            unit.rates"
                      class="mt-2 unit-detail-text"
                    >
                      Net Return:
                      <span class="font-semibold text-primary">
                        {{
                          (
                            ((unit.guaranteedRental - unit.levies - unit.rates) * 12) /
                            (unit.price * ((100 - settings.globalDiscountAmount) / 100)) * 100
                          ).toFixed(2)
                        }}
                        <sup>%</sup>
                      </span>
                    </p>
                  </div>
                </div>
                <div v-else>
                  <p
                    v-if="unit.levies &&
                          unit.rates"
                    class="mt-2 unit-detail-text"
                  >
                    Net Return:
                    <span class="font-semibold text-primary">
                      {{
                        (
                          ((unit.guaranteedRental - unit.levies - unit.rates) * 12) / unit.price * 100
                        ).toFixed(2)
                      }}
                      <sup>%</sup>
                    </span>
                  </p>
                </div>

                <div v-if="settings.allowGlobalDiscount &&
                            user.loggedIn &&
                            user.profile.answeredDiscountSurvey"
                >
                  <div v-if="settings.globalDiscountType === 'amount'">
                    <p v-if="unit.guaranteedRental &&
                              unit.levies &&
                              unit.rates"
                       class="mt-2 unit-detail-text"
                    >
                      Monthly shortfall:
                      <span class="font-semibold text-primary">
                        {{
                          (
                            (
                              unit.levies +
                              unit.rates +
                              ((-(global.interestRate / 100) / 12) *
                                ((((unit.price - settings.globalDiscountAmount) * 0.9) * -1) *
                                  Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) + 0)) /
                              (Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) - 1)
                            ) - unit.guaranteedRental
                          ).toFixed(0)

                            | prettyCurrency
                        }}
                        <sup>pm</sup>
                      </span>
                    </p>
                  </div>
                  <div v-if="settings.globalDiscountType === 'percentage'">
                    <p v-if="unit.guaranteedRental &&
                              unit.levies &&
                              unit.rates"
                       class="mt-2 unit-detail-text"
                    >
                      Monthly shortfall:
                      <span class="font-semibold text-primary">
                        {{
                          (
                            (
                              unit.levies +
                              unit.rates +
                              ((-(global.interestRate / 100) / 12) *
                                ((((unit.price * ((100 - settings.globalDiscountAmount) / 100)) * 0.9) * -1) *
                                  Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) + 0)) /
                              (Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) - 1)
                            ) - unit.guaranteedRental
                          ).toFixed(0)

                            | prettyCurrency
                        }}
                        <sup>pm</sup>
                      </span>
                    </p>
                  </div>
                </div>
                <div v-else>
                  <p  v-if="unit.levies &&
                            unit.rates"
                      class="mt-2 unit-detail-text"
                  >
                    Monthly shortfall:
                    <span class="font-semibold text-primary">
                      {{
                        (
                          (
                            unit.levies +
                            unit.rates +
                            ((-(global.interestRate / 100) / 12) *
                              (((unit.price * 0.9) * -1) *
                                Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) + 0)) /
                            (Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) - 1)
                          ) - unit.guaranteedRental
                        ).toFixed(0)

                          | prettyCurrency
                      }}
                      <sup>pm</sup>
                    </span>
                  </p>
                </div>
              </div>
            </div>

          </div>

          <!-- action buttons -->
          <div class="flex flex-col mt-10">
            <div v-if="settings.isGlobalOverrideAction === true">
              <a
                :href="settings.globalOverrideAction"
                target="_blank"
              >
                <button
                  class="bg-primary w-full mb-3 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none border border-solid focus:outline-none"
                >
                  {{settings.globalOverrideActionButton}}
                </button>
              </a>
            </div>

            <div v-else>
              <div v-if="unit.isOverrideAction === true">
                <a
                  :href="unit.unitOverrideAction"
                  target="_blank"
                >
                  <button
                    class="bg-primary w-full mb-3 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none border border-solid focus:outline-none"
                  >
                    {{unit.unitOverrideActionButton}}
                  </button>
                </a>
              </div>

              <div class="has-tooltip" v-else-if="settings.allowReserve">
                <button
                  disabled
                  class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-black tracking-wider rounded outline-none bg-gray-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                  v-if="unit.status === 'pending' || unit.status === 'pendingUnitDetails' || unit.status === 'pendingPayment'"
                >
                  Pending
                </button>

                <button
                  disabled
                  class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-black tracking-wider rounded outline-none bg-gray-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                  v-if="unit.status === 'reserved'"
                >
                  Reserved
                </button>

                <button
                  disabled
                  class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-black tracking-wider rounded outline-none bg-gray-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                  v-if="unit.status === 'sold'"
                >
                  Sold
                </button>

                <button
                  class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-green-500 border border-solid border-green-500 hover:bg-green-600 focus:outline-none"
                  v-if="unit.status === 'available' && !disableReserve && !unit.preReserved"
                  @click="unitReserve(unit.name)"
                >
                  Reserve
                </button>
                               <!-- When the unit is pre-reserved this pending button will take effect -->
                <button
                  disabled
                  class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-black tracking-wider rounded outline-none bg-gray-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                  v-if="unit.status === 'available' && !disableReserve && unit.preReserved"
                >
                  Pending
                </button>

                <button
                  class="relative bg-gray-400 w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none focus:outline-none"
                  v-if="unit.status === 'available' && disableReserve"
                >
                  <span class="text-md font-semibold uppercase">Reserve</span>
                  <span class="tooltip sentenceCaseCss lightweight -bottom-0 -right-0">This reserve button will become active once sales go live</span>
                </button>
              </div>
            </div>

            <router-link
              :to="{ name: 'home' }"
              class="w-full mb-3 px-6 py-4 uppercase text-sm text-center font-semibold text-white tracking-wider rounded outline-none bg-secondary border border-solid border-secondary focus:outline-none"
            >
              View Full Price List
            </router-link>
          </div>

          <div class="flex flex-row">
            <div class="w-1/2 pr-2">
              <button
                @click.prevent="openContactDialog()"
                class="flex flex-row items-center justify-center w-full mb-3 px-6 py-2 font-bold tracking-wider uppercase text-sm rounded outline-none text-white bg-secondary hover:bg-secondarydark border border-solid border-seondary hover:text-white focus:outline-none"
              >
                <ContactIcon :size="23" class="mr-1" />
                Contact
              </button>
            </div>

            <div class="w-1/2 pl-2">
              <AddToShortlist
                :unitID="unit.id"
                :unitName="unit.name"
                :show="true"
                :showTooltip="true"
                :homepageView="false"
              />
            </div>
          </div>

          <div
            class="flex flex-row justify-center pt-2 pb-2 border-t border-b border-gray-600"
          >
            <span class="mr-2 uppercase">Share Listing</span>
            <!-- <a
              :href="shareLinks.facebook"
              target="_blank"
              class="mr-2 cursor-pointer hover:text-blue-600"
            >
              <FacebookIcon />
            </a>

            <a
              :href="shareLinks.twitter"
              target="_blank"
              class="mr-2 cursor-pointer hover:text-blue-400"
            >
              <TwitterIcon />
            </a> -->
            <a
              :href="shareLinks.mail"
              class="mr-2 cursor-pointer hover:text-primary"
            >
              <EmailIcon />
            </a>

            <a
              class="mr-2 cursor-pointer hover:text-primary"
              v-clipboard:copy="shareLinks.link"
              @click="handleLinkClipboardCopy"
            >
              <LinkIcon />
            </a>
          </div>

          <div class="mt-4 text-xs text-center">
            <p class="text-xs py-1 text-left"><b>Please note: </b></p>
<!--            <p class="text-xs text-left">Guaranteed rentals are available for units secured on launch, calculated at 7% of the final purchase price.</p>-->
            <p class="text-xs text-left">Estimated bond cost is calculated on 90% finance over 20 years, using the current prime lending rate.</p>
          </div>
<!--          <div class="mt-2 text-xs text-center">-->
<!--            <p class="text-xs text-left">Estimated bond cost is calculated on 90% finance over 20 years, using the current prime lending rate.</p>-->
<!--          </div>-->

          <div class="mt-2 text-xs text-center">
            <p class="text-xs text-left">Unit layout may differ slightly from axonometric.</p>
          </div>
          <div class="mt-2 text-xs text-center">
            <p class="text-xs text-left">T&C's apply</p>
          </div>
        </div>
      </div>

      <!-- <ReserveDialog :reserveDialogData="reserveDialogData" :user="user" /> -->

      <!-- Reserve tooltip -->
      <div
        v-if="showTooltip"
        class="fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-60"
      >
        <div class="relative max-w-5xl">
          <button
            @click="updateTooltip()"
            class="z-50 absolute -top-5 -right-4 px-2 text-white text-4xl leading-none font-semibold border-0 bg-primary rounded-full outline-none focus:outline-none"
          >
            <span class="text-md font-semibold uppercase">Reserve</span>
          </button>
          <div class="w-full bg-black text-white py-4 px-12 rounded opacity-75">
            Available when sales go live
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../main";
import {APP_TITLE, INTEREST_RATE} from "../../Constants";

import AddToShortlist from "../../components/app/AddToShortlist.vue";
import UnitPrice from "../../components/app/shared/UnitPrice.vue";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import { unitsCollection, settingsCollection } from "../../firebase";
import isNil from "lodash/isNil";

import BathroomIcon from "vue-material-design-icons/ShowerHead.vue";
import BedroomIcon from "vue-material-design-icons/BedKingOutline.vue";
import TotalAreaIcon from "vue-material-design-icons/RulerSquare.vue";
import TotalExtAreaIcon from "vue-material-design-icons/FlipToBack.vue";
import TotalIntAreaIcon from "vue-material-design-icons/FlipToFront.vue";

import ContactIcon from "vue-material-design-icons/FaceAgent.vue";

// import PrevArrowIcon from "vue-material-design-icons/ChevronLeft.vue";
// import NextArrowIcon from "vue-material-design-icons/ChevronRight.vue";

import EmailIcon from "vue-material-design-icons/EmailOutline.vue";
// import FacebookIcon from "vue-material-design-icons/Facebook.vue";
// import TwitterIcon from "vue-material-design-icons/Twitter.vue";
import LinkIcon from "vue-material-design-icons/LinkVariant.vue";
import CarIcon from "vue-material-design-icons/Car";
import StoreIcon from "vue-material-design-icons/Store.vue";
import SnowflakeIcon from "vue-material-design-icons/Snowflake.vue";

// import ReserveDialog from '../../components/app/ReserveDialog.vue'

export default {
  name: "Unit",
  components: {
    AddToShortlist,
    UnitPrice,
    BathroomIcon,
    BedroomIcon,
    TotalAreaIcon,
    TotalExtAreaIcon,
    TotalIntAreaIcon,
    ContactIcon,
    // NextArrowIcon,
    // PrevArrowIcon,
    EmailIcon,
    // FacebookIcon,
    // TwitterIcon,
    LinkIcon,
    SnowflakeIcon,
    VueSlickCarousel,
    CarIcon,
    StoreIcon,
  },
  data: () => ({
    roleStatuses: ['admin', 'admin-viewonly', 'superadmin'],
    globals: [],
    unitID: null,
    title: APP_TITLE,
    loading: true,
    gotResult: false,
    settings: {
      allowReserve: false,
      allowDiscount: false,
      displaySplashScreen: true,
      splashScreenMessage: "",
      launchDate: undefined,
      hidePriceOnSold: false,
      logo: null,
      headerImage: null,
      globalDiscountType: null,
      globalDiscountAmount: null,
      allowGlobalDiscount: false,
      interestRate: null,
      setOneDayOnlyDiscount: false,
      isGlobalOverrideAction: false,
      globalOverrideAction: null,
      globalOverrideActionButton: null,
    },
    unit: {
      id: null,
      name: null,
      type: null,
      layout: null,
      price: null,
      discountPrice: null,
      levies: null,
      rates: null,
      guaranteedRental: null,
      twentyYearBondInstalment90: null,
      floor: null,
      bedrooms: null,
      bathrooms: null,
      parking: null,
      isAircon: false,
      aspect: null,
      view: null,
      internalArea: null,
      externalArea: null,
      totalArea: null,
      // customerFullname: null,
      // customerLastname: null,
      // customerContact: null,
      // customerEmail: null,
      // customerID: null,
      // customerAgent: null,
      // thumbnailURL: null,
      // sliderImages: null,
      // displayOnHomePage: null,
      // setGlobalDiscountPrice: null,

      isOneDayOnlyDiscount: false,
      oneDayOnlyDiscount: null,
      oneDayOnlyDiscountDateStart: null,
      oneDayOnlyDiscountDateFinish: null,

      status: null,
    },
    showTooltip: false,
    now: new Date(),
    interestRate: INTEREST_RATE / 100,
  }),
  methods: {
    updateNow() {
      this.now = Date.now();
    },
    PMT(ir, np, pv, fv, type) {
      let pmt, pvif;

      fv || (fv = 0);
      type || (type = 0);

      if (ir === 0) return -(pv + fv) / np;

      pvif = Math.pow(1 + ir, np);
      pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

      if (type === 1) pmt /= 1 + ir;

      return pmt.toFixed(2);
    },
    unitReserve(unitName) {
      this.$router.push({ name: "reserve", params: { name: unitName } });
    },
    prev() {
      this.$refs.singlecarousel.prev();
    },
    next() {
      this.$refs.singlecarousel.next();
    },
    openContactDialog() {
      bus.$emit("update:unit-contact-data", { open: true });
    },
    updateTooltip() {
      this.showTooltip = !this.showTooltip;
    },
    handleLinkClipboardCopy() {
      this.$notify({
        group: "global",
        type: "info",
        title: "Link added to Clipboard",
      });
    },
    getUnit(unitName) {
      unitsCollection
        .where("name", "==", unitName)
        .limit(1)
        .onSnapshot((snap) => {
          snap.forEach((doc) => {
            this.gotResult = true;

            this.unit.id = doc.id;
            this.unit.name = doc.data().name;
            this.unit.type = doc.data().type;
            this.unit.layout = doc.data().layout;
            this.unit.price = doc.data().price;
            this.unit.discountPrice = doc.data().discountPrice;
            this.unit.levies = doc.data().levies;
            this.unit.rates = doc.data().rates;
            this.unit.guaranteedRental = doc.data().guaranteedRental;
            this.unit.isGuaranteedRental = doc.data().isGuaranteedRental;
            this.unit.unitRentalGuaranteed = doc.data().unitRentalGuaranteed;
            this.unit.isAircon = doc.data().isAircon;
            this.unit.isOverrideAction = doc.data().isOverrideAction;
            this.unit.unitOverrideAction = doc.data().unitOverrideAction;
            this.unit.unitOverrideActionButton = doc.data().unitOverrideActionButton;
            this.unit.setGlobalDiscountPrice = doc.data().setGlobalDiscountPrice;
            this.unit.twentyYearBondInstalment90 = doc.data().twentyYearBondInstalment90;
            this.unit.floor = doc.data().floor;
            this.unit.bedrooms = doc.data().bedrooms;
            this.unit.bathrooms = doc.data().bathrooms;
            this.unit.parking = doc.data().parking;
            this.unit.aspect = doc.data().aspect;
            this.unit.view = doc.data().view;
            this.unit.internalArea = doc.data().internalArea;
            this.unit.externalArea = doc.data().externalArea;
            this.unit.totalArea = doc.data().totalArea;
            this.unit.preReserved = doc.data().preReserved;
            this.unit.mainImageURL = doc.data().mainImageURL;
            this.unit.slide2ImageURL = doc.data().slide2ImageURL;
            this.unit.slide3ImageURL = doc.data().slide3ImageURL;
            this.unit.slide4ImageURL = doc.data().slide4ImageURL;
            this.unit.slide5ImageURL = doc.data().slide5ImageURL;


            this.unit.isOneDayOnlyDiscount = doc.data().isOneDayOnlyDiscount;
            this.unit.oneDayOnlyDiscount = doc.data().oneDayOnlyDiscount;
            this.unit.oneDayOnlyDiscountDateStart = doc.data().oneDayOnlyDiscountDateStart;
            this.unit.oneDayOnlyDiscountDateFinish = doc.data().oneDayOnlyDiscountDateFinish;

            this.unit.status = doc.data().status;

            this.loading = false;
          });

          if (!this.gotResult) {
            this.$router.push({ name: "not-found" });
          }

          settingsCollection.doc("globalSettings").onSnapshot((doc) => {
            this.settings.allowReserve = doc.data().allowReserve;
            this.settings.allowDiscount = doc.data().allowDiscount;
            this.settings.displaySplashScreen = doc.data().displaySplashScreen;
            this.settings.splashScreenMessage = doc.data().splashScreenMessage;
            this.settings.launchDate = doc.data().launchDate ? doc.data().launchDate.toDate() : null;
            this.settings.hidePriceOnSold = doc.data().hidePriceOnSold || false;
            this.settings.setOneDayOnlyDiscount = doc.data().setOneDayOnlyDiscount || false;
            this.settings.allowGlobalDiscount = doc.data().allowGlobalDiscount || false;
            this.settings.globalDiscountAmount = doc.data().globalDiscountAmount || null;
            this.settings.globalDiscountType = doc.data().globalDiscountType || null;

            this.settings.isGlobalOverrideAction = doc.data().isGlobalOverrideAction || false;
            this.settings.globalOverrideAction = doc.data().globalOverrideAction || null;
            this.settings.globalOverrideActionButton = doc.data().globalOverrideActionButton || null;

            this.settings.showAvailableUnits = doc.data().showAvailableUnits;
            if (doc.data().showAvailableUnits) {
              this.filterStatus('available-only')
            }

            this.settings.isUsersOnline = doc.data().isUsersOnline;

            this.settings.allowDiscountNoSurvey = doc.data().allowDiscountNoSurvey;

            (this.settings.isLaunchDate = doc.data().isLaunchDate || false);
            this.settings.interestRate = doc.data().interestRate;
            this.settings.logo = doc.data().logo;
            this.settings.headerImage = doc.data().headerImage;
            (this.settings.allowDeposit = doc.data().allowDeposit || false);
            (this.settings.depositType = doc.data().depositType || null);
            (this.settings.depositAmount = doc.data().depositAmount || null);

            (this.settings.bypassAdmin = doc.data().bypassAdmin || false);
          });
        });
    },
  },
  created() {
    settingsCollection.orderBy("interestRate").onSnapshot((snap) => {
      let globals = [];

      snap.forEach((doc) => {
        globals.push({
          interestRate: doc.data().interestRate,

          allowReserve: doc.data().allowReserve,
          allowDiscount: doc.data().allowDiscount,
          displaySplashScreen: doc.data().displaySplashScreen,
          allowGlobalDiscount: (doc.data().allowGlobalDiscount || false),
          globalDiscountType: (doc.data().globalDiscountType || null),
          globalDiscountAmount: (doc.data().globalDiscountAmount || null),
          splashScreenMessage: doc.data().splashScreenMessage,
          launchDate: (doc.data().launchDate ? doc.data().launchDate.toDate() : null),
          logo: doc.data().logo,
          headerImage: doc.data().headerImage,
          hidePriceOnSold: (doc.data().hidePriceOnSold || false),
          allowDeposit: (doc.data().allowDeposit || false),
          depositAmount: (doc.data().depositAmount || null),
          setOneDayOnlyDiscount: (doc.data().setOneDayOnlyDiscount || false),
          isGlobalOverrideAction: (doc.data().isGlobalOverrideAction || false),
          globalOverrideAction: (doc.data().globalOverrideAction || null),
          globalOverrideActionButton: (doc.data().globalOverrideActionButton || null),
          showAvailableUnits: doc.data().showAvailableUnits,
          isUsersOnline: doc.data().isUsersOnline,
          allowDiscountNoSurvey: doc.data().allowDiscountNoSurvey,
          isLaunchDate: (doc.data().isLaunchDate || false),
          depositType: (doc.data().depositType || null),
          bypassAdmin: (doc.data().bypassAdmin || false),
        });
      });
      this.globals = globals;
    });
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    disableReserve: function () {
      // if (this.$route.name === "onedayonlyunit") {
      //   return false;
      // }

      if (this.settings && this.settings.launchDate && this.settings.launchDate > this.now) {
        if (this.unit.isOneDayOnlyDiscount === true) {
          return false;
        }
        if (this.roleStatuses.includes(this.user.profile.role) && this.globals[0].bypassAdmin === true) {
          return false;
        }
        return true;
      }
      return false;
    },
    shareLinks: function () {
      // https://twitter.com/intent/tweet?url=google.com&text=
      // https://www.linkedin.com/shareArticle?mini=true&url=google.com
      // https://pinterest.com/pin/create/button/?url=google.com&media=&description=

      let url = `${window.location.origin}/unit/${this.unit.name}`;

      const title = APP_TITLE;
      const emailSubject = `I found an apartment you may be interested in at ${title}.`;
      const emailBody = `Click here to view it online: ${url}`;
      const emailLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;

      return {
        mail: encodeURI(emailLink),
        // facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
        // twitter: `https://twitter.com/intent/tweet?url=${url}`,
        link: url,
      };
    },
  },
  mounted() {
    const name = this.$route.params.name;
    if (!isNil(name)) {
      // const unitID = this.$route.params.id
      this.getUnit(name);
    }
    setInterval(this.updateNow, 1000);
  },
};
</script>

<style>
.guaranteed-rental-container {
  width: max-content;
  display: block;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-weight: 600;
  padding: 0.25rem;
  border-radius: 0.25rem;
  z-index: 999999 !important;
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.rental-guaranteed {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.unit-detail-text {
  font-size: .750rem;
  line-height: 1.25rem;
}
.lightweight {
  font-weight: 400;
}
.headerCss {
  letter-spacing: 0.0rem;
}
.unit-spec-amounts {
  display: flex;
  border-top-width: 1px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  margin-top: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
  justify-content: space-between;
}

  .sentenceCaseCss {
    text-transform: none !important;
  }
@media only screen and (max-width: 768px) {
  .unit-spec-amounts {
    display: flex;
    border-top-width: 1px;
    border-bottom-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
    margin-top: 0.75rem;
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;
    justify-content: space-between;
    flex-direction: column;
  }
  /* .reserveTooltip {
    display: none;
  } */
}
</style>
